// Get color changer
const colorChanger = document.querySelector(".navigation-bar__color-changer");

// Set default dark mode value if not exist
if (!localStorage.getItem("darkMode")) {
    localStorage.setItem("darkMode", "light");
}

setColorMode();

// Click event for color changer (after click activate color changer)
colorChanger.addEventListener("click", () => {
    if (localStorage.getItem("darkMode") === "light") {
        localStorage.setItem("darkMode", "dark");
    } else {
        localStorage.setItem("darkMode", "light");
    }

    setColorMode();
});

// Set active class based on localStorage 'darkMode' value
function setColorMode() {
    if (localStorage.getItem("darkMode") === "dark") {
        colorChanger.classList.add("navigation-bar__color-changer--active");
        document.body.classList.add("dark");
    } else {
        colorChanger.classList.remove("navigation-bar__color-changer--active");
        document.body.classList.remove("dark");
    }
}
