// Get hamburger button
const hamburgerButton = document.querySelector(".hamburger-button");

// Get links container
const linksContainer = document.querySelector(".navigation-bar__links-container");

// ====== Events ======

// Click event for hamburger button (after click show mobile menu)
hamburgerButton.addEventListener("click", () => {
    hamburgerButton.classList.toggle("hamburger-button--active");
    linksContainer.classList.toggle("navigation-bar__links-container--active");
    document.body.classList.toggle("disable-scroll");
});

/* Scroll event, when the user scrolls down, hide the navbar.
When the user scrolls up, show the navbar */
let prevScrollpos = window.pageYOffset;
window.addEventListener("scroll", () => {
    let currentScrollPos = window.pageYOffset;
    const navBar = document.querySelector(".navigation-bar");

    if (prevScrollpos > currentScrollPos) {
        navBar.classList.remove("navigation-bar--hide");
    } else {
        navBar.classList.add("navigation-bar--hide");
    }

    prevScrollpos = currentScrollPos;
});